<template>
  <div id="content" class="flex h-screen overflow-hidden touch-manipulation">
    <div class="main-container flex w-full h-screen">
      <div
        id="content"
        class="w-full h-screen overflow-y-auto bg-primary-grey-background"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>
